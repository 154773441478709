<template>
  <div class="pdf-container">
    <iframe
      src="/pdf-service"
      width="100%"
      height="800px"
      frameborder="0"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script>
export default {
  name: 'PdfViewer',
};
</script>

<style scoped>
.pdf-container {
  width: 100%;
  height: 100%;
}
</style>